/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'

// Plugins
import { registerPlugins } from '@/plugins'

import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor'

import '@/styles/workflow.css'
import HelpTextField from './components/form/HelpTextField.vue'
import HelpSelectField from './components/form/HelpSelectField.vue'
import ErrorAlert from './components/alerts/ErrorAlert.vue'
import SuccessAlert from './components/alerts/SuccessAlert.vue'
import Loader from './components/Loader.vue'

const app = createApp(App)
app.component('v-help-text-field', HelpTextField)
app.component('v-help-select', HelpSelectField)
app.component('error-alert', ErrorAlert)
app.component('success-alert', SuccessAlert)
app.component('loader', Loader)

app.use(VueMonacoEditorPlugin, {
    paths: {
      // You can change the CDN config to load other versions
      vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.50.0/min/vs'
    },
})

registerPlugins(app)

app.mount('#app')
