
const nameRules = [
    (v:any) => !!v || 'Name is required',
]
      
const codeNameRules = [
    (v:any) => !!v || 'Code Name is required',
    (v:any) => /^[a-zA-Z0-9_\-\.]+$/.test(v) || 'Code Name can only contain letters, numbers, dashes, period, and underscores'
]

const isUrl = (val: string) => {
    try {
        new URL(val);
        return true;
    } catch (err) {
        return false;
    }
}

const urlRules =[
    (v:any) => !v || isUrl(v) || 'Please enter a valid URL'
]
const pathRules = [
    (v:any) => !v || v.startsWith('/') || 'Path must start with /'
]
const guidRules = [
(   v:any) => !v || /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(v) || 'Please enter a valid GUID'
]
const summaryRules = [(v:any) => !!v || 'Summary is required', (v:any) => v.length >= 10 || 'Summary must be at least 10 characters'];

export { nameRules, codeNameRules, urlRules, pathRules, guidRules, summaryRules }