<script setup lang="ts">
import { useHelpStore } from '@/store/help'
import { computed, useAttrs } from 'vue'
import { codeNameRules } from '@/utils/validation';

const attrs = useAttrs()
const props = defineProps({
    helpKey: { type: String, required: true },
    validators: { type: Array<String>, default: () => [] }
})

const help = useHelpStore()
const showHelp = (key: string) => {
    if (!key) {
        console.error('No help key provided')
        return
    }
    help.setContext(key.toLowerCase())
}
const color = computed(() => {
    var context = help.getContext()
    return context.value.key == props.helpKey && context.value.visible ? 'primary' : 'darkgrey'
})

const rules = [] as Array<any>;
props.validators.forEach((validatorName: String) => {
    switch (validatorName) {
        case 'required':
            rules.push((v: any) => !!v || `${attrs.label} is required.`)
            break;
        case 'codename':
            //rules.push((v: any) => /^[a-z_\.]+$/.test(v) || `${attrs.label} must be lowercase, and contain only letters, periods, and underscores.`)
            codeNameRules.forEach((rule: any) => {
                rules.push(rule)
            })
            break;
        case 'url':
            rules.push((v: any) => !v ? true : /^(http|https):\/\/[^ "]+$/.test(v) || `${attrs.label} must be a valid URL.`)
            break;
        case 'uri':
            rules.push((v: any) => !v ? true : /^(data|http|https):[^ "]+$/.test(v) || `${attrs.label} must be a valid URI.`)
            break;
    }
})

</script>
<template>
    <v-text-field 
        v-bind="$attrs"
        validate-on="blur"
        :rules="rules"
        >
        <template #append-inner>
            <v-icon size="large" @click.stop="showHelp(props.helpKey)" :color="color">
                mdi-help-circle-outline
            </v-icon>
        </template>
    </v-text-field>
</template>