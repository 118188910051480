import ConfirmDialog from "@/components/extensions/ConfirmDialog.vue"
let dialog = {} as typeof ConfirmDialog
const setDialog = (newDialog: any) => {
  	dialog = newDialog
}
const getDialog = () => {
    return dialog
}

const confirmNavigation = (confirmed: Function) => {
  	const dialog = getDialog()
	dialog.open('You have unsaved changes', 'You will lose your changes if you leave.<br/><br/>Do you want to leave this page?', {
		agreeText: 'Leave',
		cancelText: 'Stay'
	}).then((confirm:boolean) => {
        if (confirm) {
            confirmed();
        }
    });
}

export {
  setDialog,
  getDialog,
  confirmNavigation
}